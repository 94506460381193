import * as React from "react";
import HcpLayout from "../../../components/hcp/layout";
import { Seo } from "../../../components/seo";
import "./how-does-wakix-work.scss";
import bannerImg from "../../../images/why-wakix/hero-about.webp";
import bannerImgMobile from "../../../images/why-wakix/hero-about-mobile.webp";
import videoThumb from "../../../images/icons/thumb-histamin-level-video.webp";

import histamineImg from "../../../images/how-does-wakix-work/histamine-arrows.webp";
import histamineImgMobile from "../../../images/how-does-wakix-work/histamine-arrows-mobile.webp";
import { TfiAngleRight } from "react-icons/tfi";
import { Link } from "gatsby";

import iconClinicalTrial from "../../../images/icons/icon-clinical-trial-results.webp";
import waklixForYouImg from "../../../images/what-should-i-except/wakix-for-you-cta-desktop.webp";
import waklixForYouMobileImg from "../../../images/what-should-i-except/wakix-for-you-cta-mobile.webp";
import SocialIconBlock from "../../../components/socialIconBlock";
import CustomVideo from "../../../components/cards/customVideo";

import useDeviceSize from "../../../hooks/use-device-size";
import { StaticImage } from "gatsby-plugin-image";
import InnerBanner from "../../../components/InnerBanner";

const HowDoesWakixWork = () => {
    const deviceSizes = useDeviceSize();
    return (
        <HcpLayout pageClass="hcp-main how-wakix-work-page">
            <InnerBanner
                topHeading="How Does WAKIX Work?"
                bannerClass="about"
                bannerHeroImg={bannerImg}
                bannerHeroImgMobile={bannerImgMobile}
                bannerImgAlt="Chris, a real person with narcolepsy with cataplexy taking WAKIX, sitting with friends"
                heroNameImg={
                    <StaticImage
                        src="../../../images/why-wakix/hero-about-name.webp"
                        alt="Chris - Living with narcolepsy Taking WAKIX since 2021"
                        width={347}
                        height={138}
                        quality={95}
                        layout="fixed"
                        placeholder="none"
                        style={{ transition: "opacity 0.5s" }}
                        imgStyle={{ opacity: 1 }}
                    />
                }
            />

            <section className="inner-first no-pb">
                <div className="centered-content">
                    <div className="page-heading">
                        <h1 className="font-40">How Does WAKIX Work?</h1>
                        <h2>
                            WAKIX is a first-of-its-kind medication that is
                            thought to work differently
                        </h2>
                        <p>
                            While the way WAKIX works is not fully understood,
                            it is thought that WAKIX reduces excessive daytime
                            sleepiness (EDS) in people 6 years of age and older
                            with narcolepsy or cataplexy in adults with
                            narcolepsy by increasing histamine levels in the
                            brain.
                        </p>
                    </div>
                </div>
            </section>

            <section className="no-pt pd-btm">
                <div className="centered-content">
                    <div className="card-video-wrap fit">
                        <CustomVideo
                            videoId="1009247639"
                            videoIdParam="e92019a64b"
                            videoThumbnail={videoThumb}
                            altText={
                                "Video thumbnail showing WAKIX increases histamine in the brain"
                            }
                            customTagText="How WAKIX works video"
                        />
                    </div>
                </div>
            </section>

            <section className="gray-bg histamine">
                <div className="centered-content">
                    <div className="page-heading">
                        <h2>What does histamine do in the brain?</h2>
                        <p>
                            Histamine is a natural chemical in the brain that
                            works in two ways to help us stay awake during the
                            day.
                        </p>
                        <div className="img-wrap">
                            <div className="content-title">
                                Histamine:
                                {deviceSizes?.smUp ? (
                                    <img
                                        src={histamineImg}
                                        alt="Arrow pointing up to represent increased wakefulness and arrow pointing down to represent reduced sleep"
                                    />
                                ) : (
                                    <img
                                        src={histamineImgMobile}
                                        alt="Arrow pointing up to represent increased wakefulness and arrow pointing down to represent reduced sleep"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="banner-block two-columns no-pt no-pb">
                <div className="col bg-green">
                    <div className="inner cta-flt-right align-center-desk-only">
                        <div className="img-rounded-wrap">
                            <div className="image pr-0">
                                <img
                                    src={iconClinicalTrial}
                                    alt="WAKIX clinical trials icon"
                                    className="small-img"
                                />
                            </div>
                        </div>
                        <div className="text">
                            <h3>
                                See WAKIX clinical
                                <br /> trial results
                            </h3>
                            <div className="btn-wrap">
                                <Link
                                    to="/about-wakix/wakix-clinical-trials"
                                    className="btn btn-primary btn-primary--md asap"
                                >
                                    Learn more
                                    <TfiAngleRight />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col bg-img">
                    <div className="inner bg-cover cta-img-hgt">
                        {deviceSizes?.smUp ? (
                            <img
                                src={waklixForYouImg}
                                className="two-col img-flip"
                                alt="WAKIX for You Patient Case Manager with headset"
                            />
                        ) : (
                            <img
                                src={waklixForYouMobileImg}
                                className="two-col"
                                alt="WAKIX for You Patient Case Manager with headset"
                            />
                        )}
                        <div className="content cout-left-spacer">
                            <h3 className="asap green asap-bold">
                                The{" "}
                                <span className="italic asap asap-bold">
                                    WAKIX for You
                                </span>{" "}
                                patient support program is{" "}
                                <br className="show-mobile" />
                                here to help
                            </h3>
                        </div>
                        <div className="btn-wrap cout-left-spacer">
                            <Link
                                to="/wakix-for-you-support-program"
                                className="btn btn-primary btn-primary--md asap"
                            >
                                View available support
                                <TfiAngleRight />
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <SocialIconBlock />
        </HcpLayout>
    );
};

export default HowDoesWakixWork;

export const Head = () => (
    <Seo
        title="How Does WAKIX® (pitolisant) Work?"
        description="Watch this short video to learn how WAKIX is thought to work in the brain and what makes WAKIX a first-of-its-kind medication."
        keywords="how WAKIX works, how does WAkIX work, how pitolisant works, WAKIX histamine, pitolisant histamine, WAKIX mechanism of action, pitolisant mechanism of action, WAKIX first-of-its-kind "
    />
);
